import { MdOutlineSecurity } from 'react-icons/md'
import { ImExit } from 'react-icons/im'
import { VscGitPullRequestGoToChanges } from 'react-icons/vsc'

import {
  BarChartOutlined,
  EnvironmentOutlined,
  LoginOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  IdcardOutlined,
  SolutionOutlined,
  FundProjectionScreenOutlined,
  FileDoneOutlined,
  DollarOutlined,
  HistoryOutlined,
  GlobalOutlined,
  AreaChartOutlined,
  BookOutlined,
  BarcodeOutlined,
  UsergroupAddOutlined,
  FileSyncOutlined,
  //UserSwitchOutlined,
  // FormOutlined,
} from '@ant-design/icons'

import loadable from '../util/functions/loadable'
import { UserRole } from '../modules/users/domain/constants/UserRole'
import { AppPermissions } from './permissions/AppPermissions'
import RequestFeatures from '../views/RequestFeatures/RequestFeatures'
// import { isDevEnvironment } from '../util/functions/environmentFunctions'

const AuthLayout = loadable(() => import('../layouts/AuthLayout/AuthLayout'))
const MainLayout = loadable(() => import('../layouts/MainLayout/MainLayout'))

const Login = loadable(() => import('../views/Login/Login'))
// const AutoLogin = loadable(() => import('../views/AutoLogin'))
const RestorePassword = loadable(() =>
  import('../views/RestorePassword/RestorePassword')
)
const ForgotPassword = loadable(() =>
  import('../views/ForgotPassword/ForgotPassword')
)
const Forgot2fa = loadable(() => import('../views/Forgot2fa/Forgot2fa'))
const Restore2fa = loadable(() => import('../views/Restore2fa/Restore2fa'))
const Dashboard = loadable(() => import('../views/Dashboard/Dashboard'))
const Regions = loadable(() => import('../views/Regions/Regions'))
const Zones = loadable(() => import('../views/Zones/Zones'))
const Zone = loadable(() => import('../views/Zone/Zone'))
const Stores = loadable(() => import('../views/Stores/Stores'))
const Store = loadable(() => import('../views/Store/Store'))
const MyStore = loadable(() => import('../views/MyStore/MyStore'))
const Staff = loadable(() => import('../views/Staff/Staff'))
const Employee = loadable(() => import('../views/Employee/Employee'))
const AdministrationUsers = loadable(() =>
  import('../views/AdministrationUsers/AdministrationUsers')
)
const Vacancies = loadable(() => import('../views/Vacancies/Vacancies'))
//const Novelties = loadable(() => import('../views/Novelties/Novelties'))
const Report = loadable(() => import('../views/Report/Report'))
const Certificates = loadable(() =>
  import('../views/CertificatesUsers/CertificatesUsers')
)
/* const MyEmployeeInfo = loadable(() =>
  import('../views/MyEmployeeInfo/MyEmployeeInfo')
) */

const Invoices = loadable(() => import('../views/Invoices/InvoicesPage'))
const Histories = loadable(() => import('../views/History/HistoriesPage'))
const Indicators = loadable(() => import('../views/Indicators/IndicatorsPage'))
//const UserOutflow = loadable(() => import('../views/UserOutflows/UserOutflows'))
const Payroll = loadable(() => import('../views/Payroll/Payroll'))
// const Surveys = loadable(() => import('../views/Surveys/Surveys'))
const NotFound = loadable(() => import('../views/NotFound/NotFound'))
const ApprovalsUsers = loadable(() =>
  import('../views/ApprovalsUsers/ApprovalsUsers')
)
const CodeReservation = loadable(() =>
  import('../views/CodeReservation/CodeReservation')
)
const Managers = loadable(() => import('../views/Managers/ManagersPage'))
const Permissions = loadable(() =>
  import('../views/Permissions/PermissionsPage')
)
const NotFoundPermission = loadable(() =>
  import('../views/NotFoundPermissionPage/NotFoundPermissionPage')
)
const MovementSection = loadable(() =>
  import('../views/MovementSection/MovementSection')
)

export const loginPath = '/login'
export const autoLoginPath = '/auto-login'
export const restorePasswordPath = (token = ':token') =>
  `/restore-password/${token}`
export const restore2faPath = (token = ':token') => `/restore-2fa/${token}`
export const forgot2faPath = '/forgot-2fa'
export const forgotPasswordPath = '/forgot-password'
export const corsoxPath = 'https://corsox.com'
export const dashboardPath = '/dashboard'
export const zonesPath = '/zones'
export const regionsPath = '/regions'
export const zonePath = (id = ':id') => `/zones/${id}`
export const storesPath = '/stores'
export const storePath = (storeId = ':storeId') => `/stores/${storeId}`
export const myStorePath = '/my-store'
export const staffPath = '/employees'
export const employeePath = (id = ':id') => `/employees/${id}`
export const administrationUsersPath = '/administration-users'
export const vacanciesPath = '/vacancies'
export const noveltiesPath = '/novelties'
export const reportPath = '/report'
export const certificatesPath = '/certificates'
export const myEmployeeInfoPath = '/my-employee-info'
export const surveysPath = '/surveys'
export const invoicesPath = '/invoices'
export const historyPath = '/history'
export const indicatorsPath = '/indicators'
export const userOutflowsPath = '/user-outflows'
export const payrollPath = '/payroll'
export const notFoundPath = '/not-found'
export const approvalsUsersPath = '/approvals-users'
export const codeReservationPath = '/code-reservation'
export const managersPath = '/managers'
export const permissionsPath = '/permissions'
export const notFoundPermissionPath = '/not-found-permission'
export const movementSectionPath = (id = ':id') =>
  `/employees/new-movement/${id}`

export const requestFeatures = '/request-features'

export const defaultRolesRoute = {
  [UserRole.VPRRHH]: dashboardPath,
  [UserRole.ITSecurity]: administrationUsersPath,
  [UserRole.SuperUser]: dashboardPath,
  [UserRole.HRManager]: dashboardPath,
  [UserRole['GERENTE DE NOMINA']]: dashboardPath,
  [UserRole.HRCoordinator]: dashboardPath,
  [UserRole.HRGeneralist]: dashboardPath,
  [UserRole.AreaManager]: dashboardPath,
  [UserRole.FloatingManager]: dashboardPath,
  [UserRole.StoreManager]: dashboardPath,
  [UserRole.APVSr]: myEmployeeInfoPath,
  [UserRole.APV]: myEmployeeInfoPath,
  [UserRole.TechnicalDirector]: myEmployeeInfoPath,
  [UserRole.PharmacyAssistant]: myEmployeeInfoPath,
  [UserRole.RegentOfPharmacy]: myEmployeeInfoPath,
  [UserRole.Parker]: myEmployeeInfoPath,
  [UserRole.OTCConsultant]: myEmployeeInfoPath,
  [UserRole.BeautyAssistant]: myEmployeeInfoPath,
  [UserRole.SalesFloorAssistant]: myEmployeeInfoPath,
  //[UserRole.SeniorSalesFloorAssistant]: myEmployeeInfoPath,
  [UserRole.PharmacyTrainee]: myEmployeeInfoPath,
  [UserRole.BeautyAdvisor]: myEmployeeInfoPath,
  [UserRole.AssistantStoreManager]: myEmployeeInfoPath,
  [UserRole.AttachedPharmacist]: myEmployeeInfoPath,
  [UserRole.PharmacistAssistant]: myEmployeeInfoPath,
  [UserRole.PrescriptionPharmacist]: myEmployeeInfoPath,
  [UserRole.Regent]: myEmployeeInfoPath,
  [UserRole.InceTrainee]: myEmployeeInfoPath,
  [UserRole.Intern]: myEmployeeInfoPath,
  [UserRole.OTCAssistant]: myEmployeeInfoPath,
  [UserRole.APV4Hours]: myEmployeeInfoPath,
  [UserRole.WeekendSalesFloorAssistant]: myEmployeeInfoPath,
  [UserRole['DIRECTOR REGIONAL']]: myEmployeeInfoPath,
}

export const getDefaultPathByRole = (role) =>
  defaultRolesRoute[role] || loginPath

const loginRoute = {
  name: 'logIn',
  icon: <LoginOutlined />,
  path: loginPath,
  layout: AuthLayout,
  component: Login,
  hidden: true,
  guardianConfig: {
    redirectOnSession: true,
  },
}

const requestFeaturesRoute = {
  name: 'requestFeatures',
  icon: <VscGitPullRequestGoToChanges />,
  path: requestFeatures,
  layout: MainLayout,
  component: RequestFeatures,
  guardianConfig: {
    protect: true,
    permission: 0,
  },
}

// const autoLoginRoute = {
//   name: 'logIn',
//   icon: <LoginOutlined />,
//   path: loginPath,
//   component: AutoLogin,
//   hidden: true,
//   guardianConfig: {
//     redirectOnSession: true,
//   },
// }

const restorePasswordRoute = {
  name: 'restorePassword',
  icon: <LoginOutlined />,
  path: restorePasswordPath(),
  layout: AuthLayout,
  component: RestorePassword,
  hidden: true,
  guardianConfig: {
    redirectOnSession: true,
  },
}

const forgotPasswordRoute = {
  name: 'forgotPassword',
  icon: <LoginOutlined />,
  path: forgotPasswordPath,
  layout: AuthLayout,
  component: ForgotPassword,
  hidden: true,
  guardianConfig: {
    redirectOnSession: true,
  },
}

const restore2faRoute = {
  name: 'restore2fa',
  icon: <LoginOutlined />,
  path: restore2faPath(),
  layout: AuthLayout,
  component: Restore2fa,
  hidden: true,
  guardianConfig: { redirectOnSession: true },
}

const forgot2faRoute = {
  name: 'forgot2fa',
  icon: <LoginOutlined />,
  path: forgot2faPath,
  layout: AuthLayout,
  component: Forgot2fa,
  hidden: true,
  guardianConfig: { redirectOnSession: true },
}

const dashboardRoute = {
  name: 'dashboard',
  icon: <BarChartOutlined />,
  path: dashboardPath,
  layout: MainLayout,
  component: Dashboard,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_DASHBOARD,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
      UserRole.StoreManager,
      UserRole['GERENTE DE NOMINA'],
    ],
  },
}

const regionsRoute = {
  name: 'regions',
  icon: <GlobalOutlined />,
  path: regionsPath,
  layout: MainLayout,
  component: Regions,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_REGIONS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
    ],
  },
}

const zonesRoute = {
  name: 'zones',
  icon: <EnvironmentOutlined />,
  path: zonesPath,
  layout: MainLayout,
  component: Zones,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_AREAS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
    ],
  },
}

const zoneRoute = {
  name: 'zone',
  icon: <EnvironmentOutlined />,
  path: zonePath(),
  layout: MainLayout,
  component: Zone,
  hidden: true,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_AREAS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
    ],
  },
}

const storesRoute = {
  name: 'stores',
  icon: <ShopOutlined />,
  path: storesPath,
  layout: MainLayout,
  component: Stores,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_STORES,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
    ],
  },
}

const storeRoute = {
  name: 'store',
  icon: <ShopOutlined />,
  path: storePath(),
  layout: MainLayout,
  component: Store,
  hidden: true,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_DETAIL_STORES,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
      UserRole.StoreManager,
    ],
  },
}

const myStoreRoute = {
  name: 'myStores',
  icon: <ShopOutlined />,
  path: myStorePath,
  layout: MainLayout,
  component: MyStore,
  guardianConfig: {
    permission: AppPermissions.VIEW_MY_STORES,
    protect: true,
    roles: [UserRole.StoreManager],
  },
}

const staffRoute = {
  name: 'staff',
  icon: <TeamOutlined />,
  path: staffPath,
  layout: MainLayout,
  component: Staff,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_COLLABORATORS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
    ],
  },
}

const employeeRoute = {
  name: 'employee',
  icon: <IdcardOutlined />,
  path: employeePath(),
  layout: MainLayout,
  component: Employee,
  hidden: true,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_DETAIL_COLLABORATORS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
      UserRole.StoreManager,
    ],
  },
}

const administrationUsersRoute = {
  name: 'administration',
  icon: <UserOutlined />,
  path: administrationUsersPath,
  layout: MainLayout,
  component: AdministrationUsers,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_ADMINISTRATOR,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.ITSecurity,
    ],
  },
}

const vacanciesRoute = {
  name: 'vacancies',
  icon: <SolutionOutlined />,
  path: vacanciesPath,
  layout: MainLayout,
  component: Vacancies,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_VACANCIES,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
      UserRole.StoreManager,
    ],
  },
}

/* const noveltiesRoute = {
  name: 'novelties',
  icon: <CalendarOutlined />,
  path: noveltiesPath,
  layout: MainLayout,
  component: Novelties,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_NOVELTIES,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
      UserRole.StoreManager,
    ],
  },
} */

const reportRoute = {
  name: 'rrhhReport',
  icon: <FundProjectionScreenOutlined />,
  path: reportPath,
  layout: MainLayout,
  component: Report,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_REPORTS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
    ],
  },
}

const certificatesRoute = {
  name: 'certificates',
  icon: <FileDoneOutlined />,
  path: certificatesPath,
  layout: MainLayout,
  component: Certificates,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_CERTIFICATES,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
    ],
  },
}

/* const myEmployeeInfoRoute = {
  name: 'myEmployeeInfo',
  icon: <UserOutlined />,
  path: myEmployeeInfoPath,
  layout: MainLayout,
  component: MyEmployeeInfo,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_DETAIL_COLLABORATORS, //pending correction
    roles: [
      UserRole.APVSr,
      UserRole.APV,
      UserRole.TechnicalDirector,
      UserRole.PharmacyAssistant,
      UserRole.RegentOfPharmacy,
      UserRole.Parker,
      UserRole.OTCConsultant,
      UserRole.BeautyAssistant,
    ],
  },
} */

const invoicesRoute = {
  name: 'invoices',
  icon: <DollarOutlined />,
  path: invoicesPath,
  layout: MainLayout,
  component: Invoices,
  guardianConfig: {
    permission: AppPermissions.VIEW_INVOICES,
    protect: true,
    roles: [UserRole.SuperUser],
  },
}

const historyRoute = {
  name: 'history',
  icon: <HistoryOutlined />,
  path: historyPath,
  layout: MainLayout,
  component: Histories,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_ACTIVITY_LOGS,
    roles: [
      UserRole.SuperUser,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
      UserRole.VPRRHH,
    ],
  },
}

const IndicatorsRoute = {
  name: 'indicators',
  icon: <AreaChartOutlined />,
  path: indicatorsPath,
  layout: MainLayout,
  component: Indicators,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_INDICATORS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
      UserRole.HRCoordinator,
      UserRole.HRGeneralist,
      UserRole.AreaManager,
    ],
  },
}

const permissionsRoute = {
  name: 'permissions',
  icon: <MdOutlineSecurity />,
  path: permissionsPath,
  layout: MainLayout,
  component: Permissions,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_PERMISSIONS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.HRManager,
      UserRole['GERENTE DE NOMINA'],
    ],
  },
}

/* const userOutflowsRoute = {
  name: 'outflows',
  icon: <UserSwitchOutlined />,
  path: userOutflowsPath,
  layout: MainLayout,
  component: UserOutflow,
  guardianConfig: {
    protect: true,
    roles: [
      UserRole.SuperUser,
      UserRole.HRManager,
      UserRole.HRCoordinator,
      UserRole.VPRRHH,
      UserRole['GERENTE DE NOMINA'],
    ],
  },
} */

const payrollRoute = {
  name: 'payroll',
  icon: <BookOutlined />,
  path: payrollPath,
  layout: MainLayout,
  component: Payroll,
  guardianConfig: {
    permission: AppPermissions.VIEW_PAYROLL,
    protect: true,
    roles: [UserRole.VPRRHH, UserRole['GERENTE DE NOMINA'], UserRole.SuperUser],
  },
}

const notFoundRoute = {
  name: 'notFound',
  icon: <LoginOutlined />,
  path: notFoundPath,
  layout: MainLayout,
  component: NotFound,
  hidden: true,
  guardianConfig: { protect: true, permission: 0 },
}

const notFoundPermissionRoute = {
  name: 'notFoundPermission',
  icon: <LoginOutlined />,
  path: notFoundPermissionPath,
  layout: MainLayout,
  component: NotFoundPermission,
  hidden: true,
  guardianConfig: { protect: true, permission: 0 },
}

const approvalsUsersRoute = {
  name: 'approvals',
  icon: <ImExit />,
  path: approvalsUsersPath,
  layout: MainLayout,
  component: ApprovalsUsers,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_APPROVALS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.StoreManager,
      UserRole.HRCoordinator,
    ],
  },
}

const codeReservationRoute = {
  name: 'codes',
  icon: <BarcodeOutlined />,
  path: codeReservationPath,
  layout: MainLayout,
  component: CodeReservation,
  guardianConfig: {
    permission: AppPermissions.VIEW_CODES,
    protect: true,
    roles: [UserRole.SuperUser, UserRole.VPRRHH, UserRole.ITSecurity],
  },
}

const managersRoute = {
  name: 'managers',
  icon: <UsergroupAddOutlined />,
  path: managersPath,
  layout: MainLayout,
  component: Managers,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.VIEW_MANAGERS,
    roles: [
      UserRole.SuperUser,
      UserRole.VPRRHH,
      UserRole.ITSecurity,
      UserRole.HRManager,
    ],
  },
}

const movementSectionRoute = {
  name: 'newMovement',
  icon: <UsergroupAddOutlined />,
  path: movementSectionPath(),
  layout: MainLayout,
  component: MovementSection,
  hidden: true,
  guardianConfig: {
    protect: true,
    permission: AppPermissions.ACTION_CREATE_MOVEMENT,
  },
}

// const surveysRoute = {
//   name: 'surveys',
//   icon: <FormOutlined />,
//   path: surveysPath,
//   layout: MainLayout,
//   component: Surveys,
//   guardianConfig: {
//     protect: true,
//     roles: '#',
//   },
// }

const reportGroupRoute = {
  name: 'reports',
  icon: <FileSyncOutlined />,
  guardianConfig: {
    protect: true,
    permission: [AppPermissions.VIEW_REPORTS, AppPermissions.VIEW_PAYROLL],
  },
  children: [reportRoute, payrollRoute],
}

export const navRoutes = [
  loginRoute,
  restorePasswordRoute,
  forgotPasswordRoute,
  dashboardRoute,
  IndicatorsRoute,
  regionsRoute,
  zonesRoute,
  zoneRoute,
  storesRoute,
  storeRoute,
  myStoreRoute,
  staffRoute,
  employeeRoute,
  administrationUsersRoute,
  //managersRoute,
  permissionsRoute,
  approvalsUsersRoute,
  //userOutflowsRoute,
  vacanciesRoute,
  //noveltiesRoute,
  reportGroupRoute,
  certificatesRoute,
  codeReservationRoute,
  /*   myEmployeeInfoRoute,
   */ invoicesRoute,
  historyRoute,
  forgot2faRoute,
  restore2faRoute,
  notFoundRoute,
  notFoundPermissionRoute,
  movementSectionRoute,
  requestFeaturesRoute,
  // surveysRoute,
].filter(Boolean)
